.mySwiperBottom .swiper-slide {
  opacity: 1;
}

.mySwiperBottom .swiper-slide-thumb-active {
  opacity: 1;
}
.mySwiperBottom .swiper-slide-thumb-active img {
  opacity: 1;
}

.mySwiperBottom .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
